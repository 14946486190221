'use client';

import { useTranslations } from 'next-intl';
import { MouseEventHandler } from 'react';

declare let __cmp: any;

export default function CookieConsentLink({ className }: { className?: string }) {
  const t = useTranslations('main');

  const onCmp: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    __cmp('showScreenAdvanced');
  };

  return (
    <button className={className} onClick={onCmp}>
      {t('popups.cookies.open')}
    </button>
  );
}
