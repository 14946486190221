'use client';

import classNames from 'classnames';
import React from 'react';

import styles from './DarkModeSwitch.module.scss';

import { toggleDarkMode } from '@lib/root/darkmode';
import Moon from 'public/static/svg/moon.svg';
import Sun from 'public/static/svg/sun.svg';


interface DarkModeSwitchProps {
  className?: string;
  mobile?: boolean;
}

function DarkModeSwitch({
  className,
  mobile = false,
}: DarkModeSwitchProps) {
  const switchApperance = classNames(
    { 'd-none d-lg-block': !mobile, [styles.mobile]: mobile },
    styles.darkmodeSwitch,
    className,
  );

  return (
    <button onClick={() => toggleDarkMode()} className={switchApperance}>
      <div className={styles.pin}></div>
      <p className={classNames(styles.text, styles.textLight)}><Sun /></p>
      <p className={classNames(styles.text, styles.textDark)}><Moon /></p>
    </button>
  );
}

export default DarkModeSwitch;
