'use client';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useLocale } from 'next-intl';
import { useState } from 'react';
import { BiSearch } from 'react-icons/bi';

import { useBreakpoints } from '@hooks/useBreakpoints';

interface HeaderSearchProps {
  className?: string;
  placeholder: string;
  layout: 'white' | 'gray';
  buttonLabel: string;
}

export default function HeaderSearch({ className, placeholder, layout, buttonLabel }: HeaderSearchProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isBreakpointUp } = useBreakpoints();
  const lang = useLocale();
  const router = useRouter();

  const submitForm = (query: string) => {
    if (query) {
      router.push(`/${lang}/search?q=${query}`);
      return;
    }

    router.push(`/${lang}/search`);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isBreakpointUp('lg') || isExpanded) {
      submitForm(e.target.q.value);
      return;
    }

    setIsExpanded(true);
  };

  if (layout === 'white') {
    return (
      <form
        action={`/${lang}/search`}
        method="GET"
        className={cn('flex h-full translate-x-9 items-center lg:translate-x-0', className)}
        onSubmit={onSubmit}
      >
        <input
          type="text"
          name="q"
          placeholder={placeholder}
          className={cn(
            'h-10 rounded-full border border-premium-dark border-opacity-50 bg-white px-2 py-[9px] pr-6 text-sm text-black opacity-100 outline-none transition-all placeholder:text-sm placeholder:italic lg:py-[5px] dark:border-premium-500 dark:bg-premium-dark dark:text-white',
            { 'hidden opacity-0': !isExpanded && !isBreakpointUp('lg') },
          )}
        />
        <button
          type="submit"
          className={cn(
            'flex h-10 w-10 -translate-x-9 items-center justify-center rounded-br-full rounded-tr-full border border-premium-dark border-l-transparent border-opacity-50 bg-white text-xs font-bold text-premium-dark text-white transition-colors hover:bg-premium-500 lg:w-auto lg:border-l-transparent lg:px-4 lg:py-2 lg:text-gray-700 dark:border-premium-500 dark:border-l-transparent dark:bg-premium-dark dark:hover:bg-premium-500',
          )}
        >
          <span className="hidden lg:inline">{buttonLabel}</span>
          <BiSearch className="h-6 w-6 lg:ml-1 lg:h-auto lg:w-auto" />
        </button>
      </form>
    );
  }

  return (
    <form
      action={`/${lang}/search`}
      method="GET"
      className={cn('flex h-full translate-x-9 items-center lg:translate-x-0', className)}
      onSubmit={onSubmit}
    >
      <input
        type="text"
        name="q"
        placeholder={placeholder}
        className={cn(
          'rounded-full border border-gray-200 bg-white px-2 py-[9px] pr-6 text-sm text-black opacity-100 transition-all placeholder:text-sm placeholder:italic focus:border-gray-500 focus:outline-none lg:bg-gray-200 lg:py-[5px]',
          { 'hidden opacity-0': !isExpanded && !isBreakpointUp('lg') },
        )}
      />
      <button
        type="submit"
        className={cn(
          'flex h-10 w-10 -translate-x-9 items-center justify-center rounded-full bg-white text-xs font-bold text-premium-dark transition-colors hover:bg-gray-600 lg:h-8 lg:w-auto lg:bg-gray-500 lg:px-4 lg:py-2 lg:text-gray-700',
        )}
      >
        <BiSearch className="h-6 w-6 lg:mr-1 lg:h-auto lg:w-auto" />
        <span className="hidden lg:inline">{buttonLabel}</span>
      </button>
    </form>
  );
}
