'use client';

import cn from 'classnames';
import { useLocale } from 'next-intl';
import { useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import { Link, usePathname } from '@lib/navigation';
import { logout } from '@utils/api/client';
import { showNewsletter } from '@utils/newsletter';
import { useUserStore } from '@utils/user';
import { Language } from 'types/lang';
import { Icon } from '@components/icons';
import classNames from 'classnames';

interface HeaderUserMenuLabels {
  login: string;
  premium: string;
  profile: string;
  notifications: string;
  newsletter: string;
  logout: string;
}

interface HeaderUserMenuProps {
  labels: HeaderUserMenuLabels;
  version?: number;
  isPremiumEnabled: boolean;
}
export default function HeaderUserMenu({ labels, version, isPremiumEnabled }: HeaderUserMenuProps) {
  const pathname = usePathname();
  const locale = useLocale();
  const [open, setOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const userStore = useUserStore();

  useClickAway(menuRef, () => setOpen(false));

  const menuItems = useMemo(() => {
    const items = [
      {
        path: '/profile/edit',
        label: labels.profile,
      },
      {
        path: '/profile/notifications',
        label: labels.notifications,
      },
    ];

    if (showNewsletter(locale as Language)) {
      items.push({
        path: '/profile/newsletter',
        label: labels.newsletter,
      });
    }

    if (locale === 'pl' && isPremiumEnabled) {
      items.push({
        path: '/profile/premium',
        label: labels.premium,
      });
    }

    return items;
  }, [userStore.user, locale]);

  const handleLogout = async () => {
    if (isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);

    await logout();

    setOpen(false);
    setIsLoggingOut(false);
  };

  const PersonIcon =
    version == 2 ? (
      <div className="mr-2 rounded-full bg-premium-dark p-2 text-white dark:bg-white dark:text-premium-dark">
        <Icon icon="person-detailed" size={16} />
      </div>
    ) : (
      <Icon icon="person-detailed" size={20} className="mr-2" />
    );

  const buttonClasses = classNames(
    'lg:text-premium-dark lg:hover:text-premium-900 dark:hover:text-premium-100 flex items-center pl-1 pr-2 text-sm font-bold transition-all dark:text-white',
    {
      'border border-premium-dark dark:border-premium-500 rounded-full p-1': version == 2,
    },
  );

  if (!userStore.isLoggedIn || !userStore.user) {
    return (
      <Link href={`/login?from=${pathname}`} rel="nofollow" className={buttonClasses}>
        {PersonIcon}
        <span className="hidden text-nowrap lg:block">{labels.login}</span>
      </Link>
    );
  }

  return (
    <div className="relative">
      <button className={buttonClasses} onClick={() => setOpen((prevState) => !prevState)}>
        {PersonIcon}
        <span className="hidden text-nowrap lg:block">{userStore.user.firstName}</span>
      </button>
      <div
        className={cn(
          'absolute left-0 top-full z-10 mt-3 rounded-lg border border-gray-600 bg-white transition-opacity',
          {
            'pointer-events-none opacity-0': !open,
            'opacity-100': open,
          },
        )}
        ref={menuRef}
      >
        {menuItems.map((item, index) => (
          <Link
            href={item.path}
            key={item.path}
            className={cn(
              'block w-full p-2 text-left text-xs transition-colors hover:bg-gray-200 dark:text-black dark:hover:text-black',
              {
                'rounded-t-lg': index === 0,
              },
            )}
          >
            {item.label}
          </Link>
        ))}
        <button
          className="block w-full rounded-b-lg p-2 text-left text-xs text-opacity-60 transition-colors hover:bg-gray-200 dark:text-black"
          disabled={isLoggingOut}
          onClick={handleLogout}
        >
          {labels.logout}
        </button>
      </div>
    </div>
  );
}
